<template>
  <div class="elv-reconciliation-matched-page">
    <div class="elv-reconciliation-matched-container">
      <ExportDataTaskHistoryInfo
        :exportType="exportProgressStatusType.reconciliationMatches"
        :isMarginTop="true"
        :isMarginBottom="false"
      />
      <div class="elv-reconciliation-matched-table-content">
        <div class="elv-reconciliation-matched-table-content-header">
          <div class="elv-reconciliation-matched-table-content-header-title">
            <p>{{ t('report.matches') }}</p>
            <p>{{ t('reconciliation.matchesInThisReconciliationTask') }}</p>
          </div>
          <div class="elv-reconciliation-matched-table-content-header-buttons">
            <div class="elv-reconciliation-matched-button-filter" @click="onOpenFilterOverlay">
              <div v-if="!filterTotal.total" class="elv-reconciliation-matched-filter-add">
                <SvgIcon name="add-line" width="14" height="14" />{{ t('common.addFilter') }}
              </div>
              <div v-else class="elv-reconciliation-matched-filter-count">
                {{ t('common.filter') }}: {{ filterTotal.total }}
              </div>
            </div>
            <ElvExportButton
              :params="{
                type: exportProgressStatusType.reconciliationMatches.value,
                extra: {
                  type: 'MATCHES',
                  reconciliationTaskId: reconciliationTaskId,
                  ...filterTotal.data
                }
              }"
              textColor="#1E2024"
              :noPermission="false"
            />
          </div>
        </div>
        <MatchedTable
          ref="matchedTableRef"
          :matchParams="matchParams"
          :tableData="matchData"
          :tableLoading="matchLoading"
          :multipleSelection="multipleSelection"
          @onResetList="getReconciliationMatchedList()"
          @onChangePage="onChangeTransactionsPage"
          @onChangeMultipleSelection="onChangeMultipleSelection"
          @onChangePageSize="onChangeTransactionsPageSize"
        />
      </div>
    </div>
  </div>
  <FilterOverlay ref="matchedTableFilterRef" @onFilterData="onFilterData" />
</template>

<script setup lang="ts">
import { emitter } from '@/lib/mitt'
import MatchedTable from './components/MatchedTable.vue'
import { exportProgressStatusType } from '@/config/global'
import FilterOverlay from './components/FilterOverlay.vue'
import ElvExportButton from '@/components/Base/ElvExportButton.vue'
import { ReconciliationMatchedListType } from '#/ReconciliationTypes'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import ExportDataTaskHistoryInfo from '../../components/ExportDataTaskHistoryInfo.vue'

const { t } = useI18n()
const route = useRoute()
const matchLoading = ref(false)
const reconciliationStore = useReconciliationStore()
const matchedTableRef = useTemplateRef('matchedTableRef')
const matchedTableFilterRef = useTemplateRef('matchedTableFilterRef')

const matchParams = ref({
  page: 1,
  limit: 20,
  sortField: 'datetime',
  sortDirection: 'DESC'
})

const multipleSelection: any = ref([])
const matchData = ref<ReconciliationMatchedListType>({ filteredCount: 0, totalCount: 0, list: [] })

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const reconciliationTaskId = computed(() => {
  return String(route.params?.reconciliationTaskId)
})

const filterTotal = computed(() => {
  const filterSaveData = reconciliationStore.reconciliationFilterTypeInfo.find((item: any) => {
    return item.type === 'MATCHED'
  })
  const filterInfo = { total: 0, data: {} }
  if (filterSaveData) {
    const entityFilterInfo = filterSaveData?.list.find((item: any) => {
      return item.entityId === entityId.value
    })
    filterInfo.data = entityFilterInfo?.data || {}
    filterInfo.total = entityFilterInfo?.total ?? 0
    return filterInfo
  }
  return filterInfo
})

const getReconciliationMatchedList = async () => {
  try {
    matchLoading.value = true

    const currentFilterData = reconciliationStore.reconciliationFilterTypeInfo.find((item: any) => {
      return item.type === 'MATCHED'
    })
    const currentSaveList = currentFilterData?.list || []
    const entityFilter = currentSaveList.find((item: any) => {
      return item.entityId === entityId.value
    })
    const filterParams: any = entityFilter?.data || {}
    const data = await reconciliationStore.fetchReconciliationMatchList(
      entityId.value,
      reconciliationTaskId.value,
      matchParams.value,
      filterParams
    )
    matchData.value = data
    matchedTableRef.value?.tableListRef?.setScrollTop(0)
  } catch (error) {
    console.log(error)
  } finally {
    matchLoading.value = false
  }
}

const onFilterData = () => {
  matchParams.value.page = 1
  getReconciliationMatchedList()
}

const onChangeTransactionsPageSize = (limit: number) => {
  matchParams.value.limit = limit
  matchParams.value.page = 1
  getReconciliationMatchedList()
}

const onChangeTransactionsPage = (page: number) => {
  matchParams.value.page = page
  getReconciliationMatchedList()
}

const onChangeMultipleSelection = (val: any) => {
  multipleSelection.value = val
}

const onOpenFilterOverlay = () => {
  matchedTableFilterRef.value?.onCheckDrawerStatus()
}

emitter.on('getReconciliationMatchedList', getReconciliationMatchedList)

watch(
  () => route,
  async () => {
    if (route.name === 'entity-reconciliation-matches' && reconciliationTaskId.value && entityId.value) {
      getReconciliationMatchedList()
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  emitter.off('getReconciliationMatchedList', getReconciliationMatchedList)
  multipleSelection.value = []
})
</script>

<style lang="scss" scoped>
.elv-reconciliation-matched-page {
  width: 100%;
  height: calc(100% - 128px);
  position: relative;
  display: flex;
  flex-direction: column;
}

.elv-reconciliation-matched-container {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .elv-reconciliation-matched-table-content {
    flex: 1;
    min-height: 0px;
    position: relative;
    box-sizing: border-box;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 70px;
      padding: 0px 20px;
      font-family: 'Plus Jakarta Sans';

      .elv-reconciliation-matched-table-content-header-title {
        p {
          font-size: 13px;
          font-weight: 400;
          line-height: 17px;
          color: #838d95;

          &:first-child {
            font-size: 18px;
            font-weight: 700;
            line-height: 23px;
            color: #0e0f11;
            margin-bottom: 4px;
          }
        }
      }

      .elv-reconciliation-matched-table-content-header-buttons {
        display: flex;
        align-items: flex-end;
        height: 100%;
        padding-bottom: 13px;

        .elv-reconciliation-matched-button-filter {
          margin-right: 8px;

          .elv-reconciliation-matched-filter-add {
            display: flex;
            align-items: center;
            color: #1e2024;
            font-family: 'Plus Jakarta Sans';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            cursor: pointer;
            height: 19px;

            svg {
              fill: #939ba2;
              margin-right: 4px;
            }
          }

          .elv-reconciliation-matched-filter-count {
            display: flex;
            padding: 0px 11px;
            height: 19px;
            box-sizing: border-box;
            align-items: center;
            border-radius: 10px;
            background: #5e85eb;
            color: #fff;
            font-family: 'Plus Jakarta Sans';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
