<template>
  <div v-if="props.type === 'Date'">
    <div
      v-for="(item, index) in currentDataList"
      :key="index"
      :style="{
        height: matchedCellHeight
      }"
      class="elv-reconciliation-matched-table-row__cell-item"
    >
      <VerticalTextCell
        :up-text="
          dayjs(item?.datetime)
            .tz(entityStore.entityDetail?.timezone ?? 'UTC')
            .format('YYYY/MM/DD')
        "
        :down-text="item?.journal?.journalNo ?? ''"
        align="left"
      />
    </div>
  </div>
  <div v-if="props.type === 'Account'">
    <div
      v-for="(item, index) in currentDataList"
      :key="index"
      :style="{
        height: matchedCellHeight
      }"
      class="elv-reconciliation-matched-table-row__cell-item"
    >
      <VerticalTextCell
        :upText="item?.chartOfAccount?.name || ''"
        upFontFamily="Plus Jakarta Sans"
        upFontSize="13px"
        :upWeight="400"
        :downText="item?.journal?.journalType?.name || ''"
        downFontFamily="Barlow"
        downFontSize="11px"
        :downWeight="400"
        align="left"
      />
    </div>
  </div>
  <div v-if="props.type === 'Dr/Cr'">
    <div
      v-for="(item, index) in currentDataList"
      :key="index"
      :style="{
        height: matchedCellHeight
      }"
      class="elv-reconciliation-matched-table-row__cell-item"
    >
      <TextCell
        :text="item?.credit !== null && !Big(item?.credit ?? '0').eq(0) ? 'Cr' : 'Dr'"
        align="center"
        font-family="Plus Jakarta Sans"
      />
    </div>
  </div>
  <div v-if="props.type === 'AuxiliaryCode'">
    <div
      v-for="(item, index) in currentDataList"
      :key="index"
      :style="{
        height: matchedCellHeight
      }"
      class="elv-reconciliation-matched-table-row__cell-item"
    >
      <AuxiliaryCodeCell :params="{ data: item }" />
    </div>
  </div>
  <div v-if="props.type === 'Currency'">
    <div
      v-for="(item, index) in currentDataList"
      :key="index"
      :style="{
        height: matchedCellHeight
      }"
      class="elv-reconciliation-matched-table-row__cell-item"
    >
      <CurrencyCell :params="{ data: item, value: { cellName: 'Currency' } }" />
    </div>
  </div>
  <div v-if="props.type === 'Amount'">
    <div
      v-for="(item, index) in currentDataList"
      :key="index"
      :style="{
        height: matchedCellHeight
      }"
      class="elv-reconciliation-matched-table-row__cell-item"
    >
      <TextCell
        :text="
          formatNumberToSignificantDigits(
            item?.credit !== null && !Big(item?.credit ?? '0').eq(0) && Number(item?.credit) !== 0
              ? item?.credit
              : item?.debit,
            2,
            '',
            false
          )
        "
        align="right"
        :weight="500"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import Big from 'big.js'
import dayjs from 'dayjs'
import { useEntityStore } from '@/stores/modules/entity'
import { formatNumberToSignificantDigits } from '@/lib/utils'
import TextCell from '@/components/Base/Table/Cell/TextCell.vue'
import CurrencyCell from '@/components/Reports/Cell/CurrencyCell.vue'
import VerticalTextCell from '@/components/Base/Table/Cell/VerticalTextCell.vue'
import AuxiliaryCodeCell from '../../../components/Cell/AuxiliaryCodeCell.vue'

const props = defineProps({
  data: {
    type: Object,
    default: {} as any
  },
  type: {
    type: String,
    default: ''
  },
  positionType: {
    type: String,
    default: 'LEFT'
  }
})

const entityStore = useEntityStore()

const currentDataList = computed(() => {
  return props.positionType === 'LEFT' ? props.data?.dataSetAList : props.data?.dataSetBList
})

const matchedCellHeight = computed(() => {
  const maxLength = Math.max(props.data?.dataSetAList.length, props.data?.dataSetBList.length) || 0
  const minLength = Math.max(props.data?.dataSetAList.length, props.data?.dataSetBList.length) || 1
  return `${(maxLength * 42) / minLength}px`
})
</script>

<style lang="scss" scoped></style>
