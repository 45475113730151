<template>
  <div class="elv-reconciliation-matched-table-wrapper">
    <el-table
      ref="tableListRef"
      v-loading="props.tableLoading"
      :data="props.tableData?.list"
      border
      width="100%"
      stripe
      row-key="reconciliationMatchId"
      class="elv-reconciliation-matched-table"
      header-cell-class-name="elv-reconciliation-matched-table-header__cell"
      header-row-class-name="elv-reconciliation-matched-table-header"
      cell-class-name="elv-reconciliation-matched-table-row__cell"
      @row-click="onCellClicked"
      @selection-change="onSelectionChange"
    >
      <el-table-column type="selection" width="40" fixed="left" reserve-selection />
      <el-table-column min-width="100" :label="t('report.matchId')" fixed="left">
        <template #default="{ row }">
          <div class="elv-reconciliation-matched-table-row__cell-item">
            <TextCell :text="row.matchNo" align="left" font-family="Plus Jakarta Sans" />
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="t('report.matchedBy')" min-width="180" fixed="left">
        <template #default="{ row }">
          <div class="elv-reconciliation-matched-table-row__cell-item">
            <VerticalTextCell
              :up-text="row?.user?.name ? `Manual Match: ${row?.user?.name}` : `Rule: ${row.reconciliationRule?.name}`"
              up-font-family="Plus Jakarta Sans"
              :down-text="
                dayjs(row?.createdAt)
                  .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                  .format('YYYY/MM/DD HH:mm')
              "
              align="left"
            />
          </div>
        </template>
      </el-table-column>

      <el-table-column class-name="divider" width="6" />
      <el-table-column :label="transformI18n(currentMatchedTypeShowInfo('LEFT').label)" header-align="center">
        <el-table-column
          v-for="colItem in currentMatchedTypeShowInfo('LEFT').tableHeaderConfig"
          :key="colItem.value"
          :label="transformI18n(colItem.label)"
          :min-width="colItem?.width || ''"
          :label-class-name="`is-group-header' ${colItem?.labelClassName || ''}`"
          :header-align="colItem?.align || 'left'"
          :align="colItem?.align || 'left'"
        >
          <template #default="{ row }">
            <component
              :is="currentComponent('LEFT')"
              v-if="currentComponent('LEFT')"
              :data="row"
              :type="colItem.value"
              positionType="LEFT"
            />
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column class-name="divider" width="6" />
      <el-table-column :label="transformI18n(currentMatchedTypeShowInfo('RIGHT').label)" header-align="center">
        <el-table-column
          v-for="colItem in currentMatchedTypeShowInfo('RIGHT').tableHeaderConfig"
          :key="colItem.value"
          :label="transformI18n(colItem.label)"
          :min-width="colItem?.width || ''"
          :label-class-name="`is-group-header' ${colItem?.labelClassName || ''}`"
          :header-align="colItem?.align || 'left'"
          :align="colItem?.align || 'left'"
        >
          <template #default="{ row }">
            <component
              :is="currentComponent('RIGHT')"
              v-if="currentComponent('RIGHT')"
              :data="row"
              :type="colItem.value"
              positionType="RIGHT"
            />
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column class-name="divider" width="6" />
      <el-table-column width="40" fixed="right">
        <template #default="{ row }">
          <div class="elv-reconciliation-matched-table__operating">
            <Popover
              trigger="focus"
              placement="bottomLeft"
              overlay-class-name="elv-reconciliation-matched-table__operating-popover"
              :offset="2"
            >
              <SvgIcon
                width="14"
                height="14"
                name="more-dian"
                class="elv-reconciliation-matched-table__operating-icon"
              />
              <template #content>
                <div class="elv-reconciliation-matched-table__operating-item" @click="onOpenMatchDetail(row)">
                  {{ t('report.matchDetail') }}
                </div>
                <div class="elv-reconciliation-matched-table__operating-item" @click="onCheckItemToUnmatched(row)">
                  {{ t('report.unmatch') }}
                </div>
              </template>
            </Popover>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <elv-pagination
      class="elv-transactions-list-pagination"
      size-popper-class="elv-transactions-pagination__sizes-popper"
      :limit="props.matchParams.limit"
      :current-page="props.matchParams.page"
      :total="props.tableData?.filteredCount"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />

    <MatchDetailDialog
      ref="matchDetailDialogRef"
      :model="matchDetailDialogType"
      :reconciliationMatchId="overlayDrawerData.currentData.reconciliationMatchId ?? ''"
      :currentData="overlayDrawerData.currentData"
      @onResetList="emit('onResetList')"
    />

    <ReconciliationTaskStatusChangeDialog
      v-if="showRefreshDataSourceDialog"
      v-model:show="showRefreshDataSourceDialog"
      v-model:loading="refreshDataSetsLoading"
      v-model:agree="agreeRemovedData"
      :title="t('report.unmatch')"
      :confirmText="t('button.confirm')"
      :cancelText="t('button.cancel')"
      @onConfirmChangeStatus="startUnmatchedReconciliation"
      @onCloseDialog="onCloseRefreshMessageBox"
    />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Toolbar from './Toolbar.vue'
import { isNumber } from 'lodash-es'
import { ElMessage } from 'element-plus'
import timezone from 'dayjs/plugin/timezone'
import { $t, transformI18n } from '@/i18n/index'
import { notification, Popover } from 'ant-design-vue'
import ReconciliationApi from '@/api/ReconciliationApi'
import { useEntityStore } from '@/stores/modules/entity'
import TextCell from '@/components/Base/Table/Cell/TextCell.vue'
import { DataTypeForReconciliation } from '@/config/reconciliation'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import MatchedTableLedgerItem from './SideTable/MatchedTableLedgerItem.vue'
import MatchedTableTransferItem from './SideTable/MatchedTableTransferItem.vue'
import VerticalTextCell from '@/components/Base/Table/Cell/VerticalTextCell.vue'
import ReconciliationTaskStatusChangeDialog from '../../components/ReconciliationTaskStatusChangeDialog.vue'
import MatchDetailDialog from '@/pages/Financials/Project/components/ReconciliationMatched/MatchDetailDialog.vue'

const props = defineProps({
  tableData: {
    type: Object,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  matchParams: {
    type: Object,
    required: true
  },
  multipleSelection: {
    type: Array,
    required: true
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const reconciliationStore = useReconciliationStore()
const emit = defineEmits(['onResetList', 'onChangePage', 'onChangePageSize', 'onChangeMultipleSelection'])

const agreeRemovedData = ref(false)
const refreshDataSetsLoading = ref(false)
const showRefreshDataSourceDialog = ref(false)

const currentUnMatchedInfo = ref<any>({})
const matchDetailDialogType = ref('MATCHED')
const tableListRef = useTemplateRef('tableListRef')
const matchDetailDialogRef = useTemplateRef('matchDetailDialogRef')
const { reconciliationTaskDetail } = reactive(reconciliationStore)
const overlayDrawerData: any = reactive({
  type: '',
  isBatch: false,
  currentData: {}
})

const matchedTypeComponentMap = {
  TRANSACTION: MatchedTableTransferItem,
  LEDGER: MatchedTableLedgerItem
}

const transferMatchType = Object.freeze([
  {
    value: 'Type',
    label: $t('common.type'),
    width: '160'
  },
  {
    value: 'DateTime',
    label: $t('common.dateTime'),
    width: '160'
  },
  {
    value: 'BaseAsset',
    label: $t('report.Base Asset'),
    width: '80'
  },
  {
    value: 'Amount',
    label: $t('report.Amount'),
    width: '100',
    align: 'right',
    labelClassName: 'elv-reconciliation-matched-table-row__amount'
  }
])

const accountingLedgerMatchType = Object.freeze([
  {
    value: 'Date',
    label: $t('common.date'),
    width: '100'
  },
  {
    value: 'Account',
    label: $t('report.Account'),
    width: '100'
  },
  {
    value: 'Dr/Cr',
    label: $t('report.Dr/Cr'),
    width: '50',
    align: 'center'
  },
  {
    value: 'AuxiliaryCode',
    label: $t('report.auxiliaryCode'),
    width: '110'
  },
  {
    value: 'Currency',
    label: $t('report.Currency'),
    width: '80'
  },
  {
    value: 'Amount',
    label: $t('report.amount'),
    width: '80',
    align: 'right',
    labelClassName: 'elv-reconciliation-matched-table-row__amount'
  }
])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currentMatchedTypeShowInfo = computed(() => (positionType: 'LEFT' | 'RIGHT') => {
  const currentMatchedTypeInfo = { label: '', value: '', tableHeaderConfig: [] as Array<any> }

  const list = props.tableData.list || []
  if (!list?.length) return currentMatchedTypeInfo
  const currentMatchedList = positionType === 'LEFT' ? list[0].dataSetAList : list[0].dataSetBList
  if (!currentMatchedList || !currentMatchedList?.length) return currentMatchedTypeInfo
  const matchedType =
    positionType === 'LEFT' ? reconciliationTaskDetail.dataSetTypeA : reconciliationTaskDetail.dataSetTypeB
  const foundType = DataTypeForReconciliation.find((item) => item.value === matchedType)
  if (foundType) {
    currentMatchedTypeInfo.label = foundType.label || ''
    currentMatchedTypeInfo.value = foundType.value || ''
    if (foundType.value === 'TRANSACTION') {
      currentMatchedTypeInfo.tableHeaderConfig = transferMatchType as any[]
    } else if (foundType.value === 'LEDGER') {
      currentMatchedTypeInfo.tableHeaderConfig = accountingLedgerMatchType as any[]
    } else {
      currentMatchedTypeInfo.tableHeaderConfig = []
    }
  }
  return currentMatchedTypeInfo
})

const currentComponent = computed(() => (positionType: 'LEFT' | 'RIGHT') => {
  const currentMenu = currentMatchedTypeShowInfo.value(positionType)
  return matchedTypeComponentMap[currentMenu.value as keyof typeof matchedTypeComponentMap]
})

const reconciliationTaskId = computed(() => {
  return String(route.params?.reconciliationTaskId || '')
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onCloseRefreshMessageBox = (unMatchedInfo?: any) => {
  if (unMatchedInfo) {
    currentUnMatchedInfo.value = unMatchedInfo
    showRefreshDataSourceDialog.value = true
    return
  }
  showRefreshDataSourceDialog.value = false
}

const startUnmatchedReconciliation = async () => {
  try {
    refreshDataSetsLoading.value = true
    await ReconciliationApi.manualReconciliationUnmatched(entityId.value, reconciliationTaskId.value, {
      reconciliationMatchIds: currentUnMatchedInfo.value?.list || [],
      isRemoveReconciliationAdjustment: agreeRemovedData.value
    })
    emit('onResetList')
    ElMessage.success(t('message.success'))
    if (currentUnMatchedInfo.value?.type === 'MATCH_DETAIL') {
      matchDetailDialogRef.value?.onCheckMatchDetailDialog()
    } else if (currentUnMatchedInfo.value?.type === 'MATCH_CHECKBOX') {
      notification.close('elv-reconciliation-matched-table-toolbar')
    }
    currentUnMatchedInfo.value = {}
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    refreshDataSetsLoading.value = false
    onCloseRefreshMessageBox()
  }
}

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

// eslint-disable-next-line no-unused-vars
const onCellClicked = async (row: any, column: any, e: any) => {
  try {
    overlayDrawerData.currentData = row
    if (
      e.target?.className !== 'elv-financials-cell-date-time-main-bottom__copy' &&
      e.target?.className !== 'elv-reconciliation-matched-table__operating' &&
      e.target?.className !== 'elv-financials-cell-date-time-main-bottom__txHash' &&
      e.target?.className !== 'elv-financials-cell-date-time-main-bottom' &&
      !isNumber(e.target?.className?.animVal?.indexOf('elv-reconciliation-matched-table__operating-icon')) &&
      e.target?.className !== 'elv-reconciliation-matched-table__operating-icon' &&
      e.target?.href?.animVal !== '#icon-more-dian'
    ) {
      if (
        ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
        !currentEntityPermission.value?.transaction?.viewDetail
      ) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      matchDetailDialogRef.value?.onCheckMatchDetailDialog()
    }
  } catch (error: any) {
    ElMessage.error(error.message)
  }
}

const onBatchDeleteReconciliationMatch = () => {
  const list = props.multipleSelection.map((item: any) => item.reconciliationMatchId)
  onCloseRefreshMessageBox({ type: 'MATCH_CHECKBOX', list })
}

const onClearSelected = () => {
  notification.close('elv-reconciliation-matched-table-toolbar')
  tableListRef.value?.clearSelection()
}

const onOpenMatchDetail = (row: any) => {
  overlayDrawerData.currentData = row
  matchDetailDialogRef.value?.onCheckMatchDetailDialog()
}

const onCheckItemToUnmatched = (row: any) => {
  onCloseRefreshMessageBox({ type: 'MATCH_ITEM', list: [row.reconciliationMatchId] })
}

const onSelectionChange = (val: any[]) => {
  emit('onChangeMultipleSelection', val)
  overlayDrawerData.isBatch = true
  if (val.length) {
    notification.open({
      key: 'elv-reconciliation-matched-table-toolbar',
      message: '',
      description: () =>
        h(Toolbar, {
          selectedTotal: val?.length,
          multipleSelection: props.multipleSelection,
          onClearSelected,
          onBatchDeleteReconciliationMatch
        }),
      class: 'elv-reconciliation-matched-table-toolbar elv-table-toolbar',
      duration: 0,
      placement: 'bottom'
    })
  } else {
    notification.close('elv-reconciliation-matched-table-toolbar')
  }
}

defineExpose({ tableListRef })

onBeforeUnmount(() => {
  onClearSelected()
  tableListRef.value?.clearSelection()
  notification.destroy()
})
</script>

<style lang="scss">
.elv-reconciliation-matched-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  // width: 100%;
  height: calc(100% - 50px);
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  // &.is-scrolling-middle,
  // &.is-scrolling-right,
  // &.is-scrolling-left {
  //   .elv-reconciliation-matched-table-header
  //     .elv-reconciliation-matched-table-header__cell.el-table-fixed-column--right {
  //     border-left: 0px !important;
  //   }
  // }

  .el-table__body-wrapper {
    height: 616px;

    .el-scrollbar__view {
      width: 100%;
      min-height: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .el-table__row {
    &.elv-table__row--striped {
      background: #f9fafb !important;

      td {
        background: #f9fafb !important;
      }
    }

    &.is-hover-row td {
      background: #f5f7fa !important;
    }

    &:hover td {
      background: transparent;
    }
  }

  .elv-reconciliation-matched-table-header {
    background: #eef4fb;

    .elv-reconciliation-matched-table-header__cell {
      background: #eef4fb;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &.el-table-fixed-column--left {
        background: #eef4fb !important;
      }

      &.el-table-fixed-column--right.is-first-column {
        background: #eef4fb !important;
      }

      &:last-child {
        border-right: 0px;
      }

      &.is-group-header {
        height: 29px;
      }

      &.is-center {
        height: 20px;

        .cell {
          justify-content: center;
        }
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
        white-space: nowrap;
      }
    }
  }

  .elv-reconciliation-matched-table-row__cell {
    padding: 0;
    min-height: 42px;
    cursor: pointer;

    &:last-child {
      border-right: 0px;
    }

    &.is-center {
      .elv-table-text {
        align-items: center;
        justify-content: space-around;
      }
    }

    .cell {
      padding: 0;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .elv-table-text {
        .normal {
          white-space: nowrap;
        }
      }
    }

    .elv-reconciliation-matched-table-row__cell-item {
      display: flex;
      align-items: center;
      padding: 0 10px;
      min-height: 42px;
      width: 100%;
      box-sizing: border-box;
      border-bottom: 1px solid #edf0f3;

      .elv-base-cell-text {
        white-space: nowrap;
      }

      &:last-child {
        border-bottom: 0px;
      }
    }

    .elv-reconciliation-matched-table-row__cell-item-transfer-type {
      .elv-base-cell-icon-title {
        svg {
          margin-right: 4px;
        }
      }
    }

    &.is-right .cell {
      text-align: right;

      .elv-reconciliation-matched-table-row__cell-item {
        justify-content: flex-end;
      }
    }

    &.is-center .cell {
      text-align: center;

      .elv-reconciliation-matched-table-row__cell-item {
        justify-content: center;
      }
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }

    .elv-reconciliation-matched-table__operating {
      width: 100%;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      .elv-reconciliation-matched-table__operating-icon {
        fill: #838d95;
        cursor: pointer;
      }

      .el-tooltip__trigger {
        color: #2f63eb;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        cursor: pointer;
        align-items: center;
      }
    }
  }

  .el-table__footer-wrapper {
    height: 42px;

    .el-table__footer {
      height: 100%;

      .cell.elv-reconciliation-matched-table-row__total {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .cell.elv-reconciliation-matched-table-row__amount {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;

        p {
          color: #838d95;
          font-family: 'Barlow';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: none;
        }
      }
    }
  }

  .is-group .el-table__cell {
    background: none !important;
  }

  .is-group .is-center {
    justify-content: center;
  }

  .is-group .elv-reconciliation-matched-table-header__cell {
    &:last-child {
      border-right: 1px solid #e4e7eb !important;
    }
  }

  .el-table__header {
    .divider {
      border: none;
      border-left: 1px solid #ced7e0;
      border-right: 1px solid #ced7e0;
      background: #eef4fb !important;
      border-bottom: none;
    }

    .divider + th {
      border-left: 1px solid #e4e7eb;
    }
  }

  .el-table__body {
    min-height: 100%;

    td.divider {
      border: none;
      border-left: 1px solid #ced7e0;
      border-right: 1px solid #ced7e0;
      background: #eef4fb !important;
    }

    .divider + th {
      border-left: 1px solid #e4e7eb;
    }
  }

  .el-table__empty-block {
    width: 100% !important;
    height: 100% !important;
  }
}

.elv-transactions-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}

.ant-popover.elv-reconciliation-matched-table__operating-popover {
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px;
  background: #fff;
  box-shadow:
    0px 0px 1px 0px rgba(0, 0, 0, 0.3),
    0px 2px 6px 0px rgba(0, 0, 0, 0.05);

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    padding: 0px;
    box-shadow: none;
    width: 161px;
    border-radius: 6px;
  }

  .elv-reconciliation-matched-table__operating-item {
    display: flex;
    height: 32px;
    width: 100%;
    padding: 0px 8px;
    box-sizing: border-box;
    align-items: center;
    align-self: stretch;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: background 0.1s;

    svg {
      margin-right: 8px;
    }

    &:hover {
      background: #f9fafb;
    }
  }
}
</style>
<style lang="scss" scoped>
.elv-reconciliation-matched-table-wrapper {
  width: 100%;
  height: calc(100% - 70px);
  position: relative;
}
</style>
