<template>
  <div v-if="props.type === 'Type'">
    <div
      v-for="(item, index) in currentDataList"
      :key="index"
      :style="{
        height: matchedCellHeight
      }"
      class="elv-reconciliation-matched-table-row__cell-item-transfer-type"
    >
      <IconWithTitleCell
        :title="transformI18n(transactionTypeInfo(item?.type ?? '').label)"
        :svgName="transactionTypeInfo(item?.type ?? '').icon"
        titleFontFamily="Plus Jakarta Sans"
        titleSize="13px"
        :titleWeight="400"
        :svgSize="14"
      />
    </div>
  </div>
  <div v-if="props.type === 'DateTime'">
    <div
      v-for="(item, index) in currentDataList"
      :key="index"
      :style="{
        height: matchedCellHeight
      }"
      class="elv-reconciliation-matched-table-row__cell-item"
    >
      <DateTimeCell :params="{ data: item }" />
    </div>
  </div>
  <div v-if="props.type === 'BaseAsset'">
    <div
      v-for="(item, index) in currentDataList"
      :key="index"
      :style="{
        height: matchedCellHeight
      }"
      class="elv-reconciliation-matched-table-row__cell-item"
    >
      <CurrencyCell :params="{ data: item, value: { cellName: 'Currency' } }" />
    </div>
  </div>
  <div v-if="props.type === 'Amount'">
    <div
      v-for="(item, index) in currentDataList"
      :key="index"
      :style="{
        height: matchedCellHeight
      }"
      class="elv-reconciliation-matched-table-row__cell-item"
    >
      <TextCell :text="formatNumberToSignificantDigits(item?.amount ?? 0, 2, '', false)" align="right" :weight="500" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { flatMap } from 'lodash-es'
import { transactionType } from '@/config'
import { transformI18n } from '@/i18n/index'
import { formatNumberToSignificantDigits } from '@/lib/utils'
import TextCell from '@/components/Base/Table/Cell/TextCell.vue'
import DateTimeCell from '@/components/Reports/Cell/DateTimeCell.vue'
import CurrencyCell from '@/components/Reports/Cell/CurrencyCell.vue'
import IconWithTitleCell from '@/components/Base/Table/Cell/IconWithTitleCell.vue'

const props = defineProps({
  data: {
    type: Object,
    default: {} as any
  },
  type: {
    type: String,
    default: ''
  },
  positionType: {
    type: String,
    default: 'LEFT'
  }
})

const currentDataList = computed(() => {
  return props.positionType === 'LEFT' ? props.data?.dataSetAList : props.data?.dataSetBList
})

const matchedCellHeight = computed(() => {
  const maxLength = Math.max(props.data?.dataSetAList.length, props.data?.dataSetBList.length) || 0
  const minLength = Math.max(props.data?.dataSetAList.length, props.data?.dataSetBList.length) || 1
  return `${(maxLength * 42) / minLength}px`
})

const transactionTypeInfo = computed(() => (type: string) => {
  let data = { label: '', value: '', icon: '' }
  const transactionTypeList = flatMap(transactionType, 'list')
  transactionTypeList.forEach((item) => {
    if (item.value === type) {
      data = item
    }
  })
  return data
})
</script>

<style lang="scss" scoped></style>
