<template>
  <el-drawer
    v-model="isShowFilterDrawer"
    :show-close="false"
    modal-class="elv-report-journal-overlay"
    class="elv-report-journal-drawer-filter"
    :before-close="onCheckDrawerStatus"
    destroy-on-close
    :size="360"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-report-journal-drawer-filter-header__title">
        {{ t('common.filter') }}
      </h4>
      <div class="elv-report-journal-drawer-filter-header-operating">
        <div class="elv-report-journal-drawer-filter-header__clear">
          <SvgIcon name="reset" width="14" height="14" class="elv-report-journal-drawer-filter-header__close" />
          <span @click="onClearData('')">{{ t('button.clear') }}</span>
        </div>
        <SvgIcon
          name="close"
          width="20"
          height="20"
          class="elv-report-journal-drawer-filter-header__close"
          @click="onCheckDrawerStatus"
        />
      </div>
    </template>
    <el-scrollbar v-loading="loading" wrap-class="elv-journal-drawer-filter-wrap">
      <div class="elv-journal-drawer-filter-item">
        <div class="elv-journal-drawer-filter-item-title">
          <p>{{ t('report.matchId') }}</p>
          <span v-show="filterData?.matchNo" @click="onClearData('matchNo')">{{ t('button.clear') }}</span>
        </div>
        <div
          class="elv-journal-drawer-filter-item-referenceNo"
          :class="{
            'is-unEmpty': filterData?.matchNo
          }"
        >
          <el-input v-model="filterData.matchNo" :placeholder="`Input ...`" />
        </div>
      </div>
      <div v-if="taskDetailHasTransaction" class="elv-journal-drawer-filter-item">
        <div class="elv-journal-drawer-filter-item-title">
          <p>{{ t('reconciliation.transactionId') }}</p>
          <span v-show="filterData?.transactionTxHash" @click="onClearData('transactionTxHash')">{{
            t('button.clear')
          }}</span>
        </div>
        <div
          class="elv-journal-drawer-filter-item-referenceNo"
          :class="{
            'is-unEmpty': filterData?.transactionTxHash
          }"
        >
          <el-input v-model="filterData.transactionTxHash" :placeholder="`Input ...`" />
        </div>
      </div>
      <div v-if="taskDetailHasLedger" class="elv-journal-drawer-filter-item">
        <div class="elv-journal-drawer-filter-item-title">
          <p>{{ t('project.costBasis.journalNo') }}</p>
          <span v-show="filterData?.ledgerJournalNo" @click="onClearData('ledgerJournalNo')">{{
            t('button.clear')
          }}</span>
        </div>
        <div
          class="elv-journal-drawer-filter-item-referenceNo"
          :class="{
            'is-unEmpty': filterData?.ledgerJournalNo
          }"
        >
          <el-input v-model="filterData.ledgerJournalNo" :placeholder="`Input ...`" />
        </div>
      </div>
      <div v-if="taskDetailHasLedger" class="elv-journal-drawer-filter-item">
        <div class="elv-journal-drawer-filter-item-title">
          <p>{{ t('report.referenceNo') }}</p>
          <span v-show="filterData?.ledgerReferenceNo" @click="onClearData('ledgerReferenceNo')">{{
            t('button.clear')
          }}</span>
        </div>
        <div
          class="elv-journal-drawer-filter-item-referenceNo"
          :class="{
            'is-unEmpty': filterData?.ledgerReferenceNo
          }"
        >
          <el-input v-model="filterData.ledgerReferenceNo" :placeholder="`Input ...`" />
        </div>
      </div>
      <div class="elv-journal-drawer-filter-item">
        <div class="elv-journal-drawer-filter-item-title">
          <p>{{ t('report.matchedBy') }}</p>
          <span v-show="filterData?.userId" @click="onClearData('userId')">{{ t('button.clear') }}</span>
        </div>
        <SingleChoiceSelect
          v-model="filterData.userId"
          :placeholder="t('common.select')"
          class="elv-recondition-matched-list-user-select"
          :class="{ 'is-unEmpty': filterData.userId?.length }"
          width="328px"
          :options="matchedByList"
        />
      </div>
      <div class="elv-journal-drawer-filter-item">
        <div class="elv-journal-drawer-filter-item-title">
          <p>{{ t('reconciliation.reason') }}</p>
          <span v-show="filterData?.reconciliationReasonId" @click="onClearData('reconciliationReasonId')">{{
            t('button.clear')
          }}</span>
        </div>
        <SingleChoiceSelect
          v-model="filterData.reconciliationReasonId"
          :placeholder="t('common.select')"
          class="elv-recondition-matched-list-user-select"
          :class="{ 'is-unEmpty': filterData.reconciliationReasonId?.length }"
          width="328px"
          :options="reconciliationTaskReasonOptions"
        />
      </div>
    </el-scrollbar>
    <template #footer>
      <elv-button
        :loading="saveLoading"
        type="primary"
        round
        width="204"
        height="36"
        class="elv-report-journal-drawer-filter-footer__save"
        @click="onSave"
      >
        {{ t('button.apply') }}
      </elv-button>
    </template>
  </el-drawer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { cloneDeep, isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import ReconciliationApi from '@/api/ReconciliationApi'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import SingleChoiceSelect from '@/components/Base/SingleChoiceSelect.vue'

interface matchedByListType {
  alt: string
  value: string
  label: string
}

const { t } = useI18n()
const route = useRoute()
const reconciliationStore = useReconciliationStore()

const loading = ref(false)
const saveLoading = ref(false)
const isShowFilterDrawer = ref(false)
const matchedByList = ref<matchedByListType[]>([])

const emit = defineEmits(['onFilterData'])

const filterData = reactive({
  matchNo: '',
  ledgerJournalNo: '',
  ledgerReferenceNo: '',
  transactionTxHash: '',
  reconciliationReasonId: '',
  reconciliationRuleId: '',
  userId: ''
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const reconciliationTaskId = computed(() => {
  return route.params?.reconciliationTaskId as string
})

const reconciliationTaskReasonOptions = computed(() => {
  return reconciliationStore.reconciliationTaskReasonList.map((item: any) => {
    return {
      value: item.reconciliationReasonId,
      label: item.name
    }
  })
})

const taskDetailHasTransaction = computed(() => {
  return (
    reconciliationStore.reconciliationTaskDetail?.dataSetTypeA === 'TRANSACTION' ||
    reconciliationStore.reconciliationTaskDetail?.dataSetTypeB === 'TRANSACTION'
  )
})

const taskDetailHasLedger = computed(() => {
  return (
    reconciliationStore.reconciliationTaskDetail?.dataSetTypeA === 'LEDGER' ||
    reconciliationStore.reconciliationTaskDetail?.dataSetTypeB === 'LEDGER'
  )
})

const getMatchedFilterList = async () => {
  try {
    loading.value = true
    reconciliationStore.fetchReconciliationTaskReasonList(entityId.value, reconciliationTaskId.value)
    const { data } = await ReconciliationApi.getReconciliationMatchByList(entityId.value, reconciliationTaskId.value)
    const ruleList = data.reconciliationRuleList.map((item) => {
      return {
        alt: 'rule',
        value: item.reconciliationRuleId,
        label: item.name
      }
    })
    const userList = data.userList.map((item) => {
      return {
        alt: 'user',
        value: item.userId,
        label: item.name
      }
    })
    matchedByList.value = [...ruleList, ...userList]
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    loading.value = false
  }
}

const onClearData = (type?: string) => {
  switch (type) {
    case 'matchNo':
      filterData.matchNo = ''
      break
    case 'ledgerJournalNo':
      filterData.ledgerJournalNo = ''
      break
    case 'ledgerReferenceNo':
      filterData.ledgerReferenceNo = ''
      break
    case 'transactionTxHash':
      filterData.transactionTxHash = ''
      break
    case 'reconciliationReasonId':
      filterData.reconciliationReasonId = ''
      break
    case 'userId':
      filterData.userId = ''
      break
    default:
      filterData.matchNo = ''
      filterData.ledgerJournalNo = ''
      filterData.ledgerReferenceNo = ''
      filterData.transactionTxHash = ''
      filterData.reconciliationReasonId = ''
      filterData.reconciliationRuleId = ''
      filterData.userId = ''
      break
  }
}

const onCheckDrawerStatus = () => {
  isShowFilterDrawer.value = !isShowFilterDrawer.value
}

defineExpose({ onCheckDrawerStatus })

const onSave = async () => {
  try {
    saveLoading.value = true
    const newFilterData = JSON.parse(JSON.stringify(filterData))
    Object.keys(newFilterData).forEach((item: any) => {
      if (!newFilterData[item]?.length) {
        delete newFilterData[item]
      }
    })
    const copyFilterData: any = cloneDeep(newFilterData)
    if (copyFilterData.userId) {
      const currentMatchedBy = matchedByList.value.find((item: any) => {
        return item.value === copyFilterData.userId
      })
      if (currentMatchedBy?.alt === 'rule') {
        copyFilterData.reconciliationRuleId = copyFilterData.userId
        delete copyFilterData.userId
      } else {
        delete copyFilterData.reconciliationRuleId
      }
    }
    reconciliationStore.editReconciliationFilter(entityId.value, copyFilterData)

    emit('onFilterData')
    onCheckDrawerStatus()
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

watch(
  () => isShowFilterDrawer.value,
  async () => {
    if (isShowFilterDrawer.value) {
      onClearData()
      await getMatchedFilterList()
      const matchedList = reconciliationStore.reconciliationFilterTypeInfo.find((item: any) => {
        return item.type === 'MATCHED'
      })
      if (matchedList) {
        const currentEntityFilterInfo = matchedList.list.find((item: any) => item.entityId === entityId.value)
        if (!isEmpty(currentEntityFilterInfo?.data)) {
          filterData.matchNo = currentEntityFilterInfo?.data?.matchNo || ''
          filterData.ledgerJournalNo = currentEntityFilterInfo?.data?.ledgerJournalNo || ''
          filterData.ledgerReferenceNo = currentEntityFilterInfo?.data?.ledgerReferenceNo || ''
          filterData.reconciliationReasonId = currentEntityFilterInfo?.data?.reconciliationReasonId || ''
          filterData.transactionTxHash = currentEntityFilterInfo?.data?.transactionTxHash || ''
          filterData.userId =
            currentEntityFilterInfo?.data?.userId || currentEntityFilterInfo?.data?.reconciliationRuleId || ''
        }
      }
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.elv-report-journal-drawer-filter {
  .elv-report-journal-drawer-filter-header__title {
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #1e2024;
    margin: 0;
  }

  .elv-report-journal-drawer-filter-header-operating {
    display: flex;
    align-items: center;
  }

  .elv-report-journal-drawer-filter-header__clear {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 8px;

    span {
      display: block;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 6px;
      padding-right: 8px;
      border-right: 1px solid #dde1e6;
    }
  }

  .elv-report-journal-drawer-filter-header__close {
    padding: 4px;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.elv-report-journal-drawer-filter {
  .el-drawer__header {
    padding: 10px 0px;
    margin: 0 24px;
    border-bottom: 1px solid #d0d4d9;
    height: 48px;
    box-sizing: border-box;
    display: flex;
  }

  .el-drawer__body {
    padding: 16px 0px;
    margin-bottom: 16px;
  }

  .elv-journal-drawer-filter-wrap {
    padding: 0px 16px;

    .el-scrollbar__view {
      height: 100%;
    }
  }

  .el-drawer__footer {
    text-align: left;
    padding: 0px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .elv-journal-drawer-filter-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    .elv-journal-drawer-filter-item-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'Plus Jakarta Sans';
      margin-bottom: 4px;
      height: 24px;

      p {
        color: #1e2024;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      span {
        color: #5e85eb;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
      }
    }

    .elv-select-journal-filter-date-picker {
      border-radius: 0px 0px 4px 4px;
      border: 1px solid #dde1e6;
      box-sizing: border-box;
      height: 44px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);

      &.is-unEmpty {
        border-color: #5e85eb;
        background-color: #f7f9fe;
      }
    }

    .elv-journal-drawer-filter-item-referenceNo {
      &.is-unEmpty .el-input .el-input__wrapper {
        border-color: #5e85eb;
        background-color: #f7f9fe;
      }
    }
  }

  .elv-report-journal-drawer-filter-info {
    margin-bottom: 16px;

    .elv-report-journal-drawer-filter-info__title {
      display: flex;
      align-items: center;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #838d95;

      span {
        width: 1px;
        height: 12px;
        display: block;
        background: #dde1e6;
        margin: 0 6px;
      }
    }
  }
}

.el-select.is-unEmpty {
  &.is-not-underlined {
    .el-select__wrapper.is-hovering {
      border-bottom: 0px !important;
    }
  }

  .el-select__wrapper {
    border-color: #5e85eb;
    background-color: #f7f9fe;
    min-height: 44px;
    height: fit-content;

    &.is-hovering {
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08) !important;
    }

    .el-tag {
      border-radius: 3px;
      border: 1px solid #e3e7f1;
      background: #fff;
      display: flex;
      padding: 0px 4px 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      box-sizing: border-box;
      width: fit-content;

      .el-tag__content {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;

        .el-select__tags-text {
          display: flex;
          align-items: center;

          img {
            width: 14px;
            height: 14px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
          }
        }
      }

      .el-icon.el-tag__close {
        width: 12px;
        height: 12px;
        color: #aaafb6;
        margin-left: 2px;

        &:hover {
          color: #fff;
          background-color: #909399;
        }
      }
    }
  }

  .el-select__selected-item {
    display: flex;

    .el-tag {
      border-radius: 3px;
      border: 1px solid #e3e7f1;
      background: #fff;
      display: flex;
      padding: 0px 4px 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      box-sizing: border-box;
      width: fit-content;

      .el-select__tags-text {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }

      .el-icon.el-tag__close {
        width: 12px;
        height: 12px;
        margin-left: 2px;
      }
    }
  }
}

.el-select.is-select-value {
  .el-select__wrapper {
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
    border-bottom: 0px;
  }
}

.el-select.is-select-value-top {
  .el-select__wrapper {
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  }
}

.elv-select-journal-filter-dropdown {
  .elv-select-journal-filter-type__all,
  .elv-select-journal-filter-currency__all {
    width: 100%;
    box-sizing: border-box;
    padding-left: 8px;
    margin-right: 0px;
    border-top: 1px solid #edf0f3;
    border-bottom: 1px solid #edf0f3;

    &:first-of-type {
      border-top: 0px;
    }

    .el-checkbox__label {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .el-checkbox-group {
    .el-checkbox {
      .el-checkbox__label {
        display: flex;
        align-items: center;

        img {
          width: 18px;
          height: 18px;
          display: block;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }

  &.journal-type {
    padding: 0px;

    .el-select-dropdown__empty {
      padding: 0px;
    }
  }

  .el-select-dropdown.is-multiple .el-select-dropdown__item {
    padding-left: 8px;

    &.selected::after,
    &.is-selected::after {
      width: 0;
      height: 0;
    }
  }
}

.elv-journal-drawer-filter-tips.el-popper {
  display: flex;
  gap: 6px;
  max-width: 400px;
  max-height: 200px;
  flex-wrap: wrap;
}

.el-popper.elv-journal-filter-type-drawer-category-popper {
  width: fit-content !important;
  padding: 0px;
  min-height: 280px;
  height: fit-content;
  box-sizing: border-box;

  .elv-journals-drawer-event-option-title {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    height: 39px;
    background: #ffffff;
    border-bottom: 1px solid #edf0f3;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #636b75;

    span {
      font-weight: 600;
      color: #0e0f11;
    }
  }
}
</style>
